<template>
    <div>
        <div class="el-breadcrumb-box">
            <div class="el-breadcrumb">
                <span class="el-breadcrumb__item">
                    <span class="el-breadcrumb__item__inner">动态详情</span>
                </span>
            </div>
        </div>


        <div class="detail-content"  >

            <div v-loading="detailLoading">

            <div class="f-title-box">
                <div class="f-title" style="margin:10px 0"><span class="title">作者信息</span> </div>
            </div>
            <div>
                <el-avatar shape="square"  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"></el-avatar>
                <el-form ref="form" label-position="left" >
                    <el-form-item label="用户名">
                        <span>{{detail.user_name}}</span>
                    </el-form-item>

                    <el-form-item label="标题">
                        <span>{{detail.title}}</span>
                    </el-form-item>

                    <el-form-item label="内容">
                        <span>{{detail.content}}</span>
                    </el-form-item>
                </el-form>
            </div>

            <div class="f-title-box">
                <div class="f-title" style="margin-top:20px" ><span class="title">图片列表</span> </div>
            </div>

            <div style="padding:10px 0">
                <div class="block" >
                    <el-image v-for="item in detail.pic_data" :key="item.index" class="detail-img"
                              style="width: 200px; height: 200px"
                              :src="imageUrl+item"
                              :preview-src-list="srcList"
                    >
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>

                </div>

            </div>
            </div>

            <div class="f-title-box">
                <div class="f-title"  ><span class="title">评论列表</span> </div>

                <div class="table-content">

                    <el-table
                            :header-cell-style="{padding:0}"
                            :cell-class-name="rowItem"
                            ref="singleTable"
                            :data="tableData"
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            v-loading="loading"
                            row-key="id"
                            :tree-props="{children: 'son_comment', hasChildren: 'son_comment'}"
                            style="width: 100%;background: #fff;">

                        <el-table-column
                                label="ID"
                                property="id"
                                width="80">
                        </el-table-column>
                        <el-table-column
                                label="内容">
                            <template slot-scope="scope">
                                <span>{{scope.row.content}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                property="index"
                                label="用户id"
                                width="200">
                            <template slot-scope="scope">
                                <span>{{scope.row.user_id}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                property="index"
                                label="用户名称"
                                width="200">
                            <template slot-scope="scope">
                                <span>{{scope.row.user_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                property="created_at"
                                label="评论时间"
                                width="200">
                        </el-table-column>
                        <el-table-column
                                property="address"
                                label="操作">
                            <template slot-scope="scope">
                                <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                            </template>
                        </el-table-column>
                    </el-table>

                </div>

                <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                    <div style="clear: both"></div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "detail",
        data(){
            return{
                id:'',
                srcList:[],
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                tableData: [],
                detailLoading:false,
                detail:[]
            }
        },
        methods:{
            ...mapActions('user',['getUserSocialDetail','getUserSocialCommentList']),
            ...mapActions('common',['delete']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                    social_id:this.id
                }
                this.getUserSocialCommentList(data).then(res => {
                    console.log(res)
                    this.tableData =  res.data.list
                    this.total = res.data.total
                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            getDetail(id){
                this.detailLoading = true
                this.getUserSocialDetail({id:id}).then(res => {
                    this.detail = res.data
                    res.data.pic_data.forEach(item => {
                        this.srcList.push(imageUrl+item)
                    })
                    this.detailLoading = false
                })
            },
            deleted(row){
                this.loading = true
                this.delete({id:row.id,model:"SocialComment"}).then(res => {
                    this.getList()
                    if(res.ret  == 0){
                        this.$message.success('删除成功')
                    }

                })
            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getDetail(this.$route.params.id)
            this.getList()
        }
    }
</script>

<style>
.detail-img{
    padding:10px
}
.el-form-item{
    margin:0
}
</style>